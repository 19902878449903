import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

const schema = yup.object().shape({
    oldPassword: yup
        .string("La contraseña debe ser alfanumérica")
        .required("Debes ingresar una contraseña")
        .min(6, "La contraseña debe tener al menos 6 caráteres"),
    passwordA: yup
        .string("La contraseña debe ser alfanumérica")
        .required("Debes ingresar una contraseña")
        .min(6, "La contraseña debe tener al menos 6 caráteres"),
    passwordB: yup
        .string("La contraseña debe ser alfanumérica")
        .required("Debes ingresar una contraseña")
        .min(6, "La contraseña debe tener al menos 6 caráteres")
        .oneOf([yup.ref('passwordA')], 'Las nuevas contraseñas no son iguales'),
})

export default yupResolver(schema)
import React from 'react';
import Navigation from "../Navigation";

const Layout = ({ children }) => {
    return (
        <>
            <Navigation />
            <div className="container mb-5">
                { children }
            </div>
            <br/>
            <br/>
            <br/>
            <hr className="mb-5"/>

            <div className="row">
                <div className="col-12"></div>
            </div>

            <footer className="fixed-bottom">

                <div className="container">
                    <div className="row">
                        <div className="col ">
                            <div className="row text-center">
                                <div className="col ">
                                    <img className="rounded my-1" src="/img/eslogan_unipamplona.png" alt="logo up" height="75px"/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </footer>
        </>
    );
};

export default Layout;
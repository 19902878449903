import React, {useState} from 'react';
import Resizer from "react-image-file-resizer";
import axios from "axios";
import {endpoint} from "../../helpers/configRequest";
import routes from "../../helpers/routes";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            480,
            640,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

const dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
        splitDataURI[0].indexOf("base64") >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
};

const blobToData = (blob) => {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
    })
};

const InscripcionCrearPage = ({location}) => {

    const {admitido, recaudo} = location.state;
    const {pagos} = recaudo;

    //const {pruebaEnviarDatos} = useAuth();

    const history = useHistory();

    const [newImage, setNewImage] = useState(null);
    //const [decodeImage] = useState(null);

    const [files, setFiles] = useState(null);

    const onFormSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('imagen', newImage);
        formData.append('documento', admitido.documento);
        formData.append('nombre', admitido.nombre);
        formData.append('activo', true);
        formData.append('fechaInicio', new Date().toString());

        const session = {
            token: localStorage.getItem('token')
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'x-access-token': session.token
            },
        };
        const path = '/control/crear-inscripcion'
        const url = `${endpoint}${path}`;
        try{
            const resp = await axios.post(url, formData, config)
            console.log(resp.data)


            history.push({
                pathname: routes.control.inscripcionValidar
            });
            return toast.success("El estudiante  Registrado!");
        } catch (e){
            console.log(e)
        }
    }

    const onChange = async (event) => {
        try{
            const file = event.target.files[0];
            //setFiles(event.target.files[0])
            //console.log(file)
            const image = await resizeFile(file);
            setNewImage(image);
            const newFile = dataURIToBlob(image);

            // proceso inverso Blob to dataURL
            const resData = await blobToData(newFile);

            setFiles(resData)

        } catch (e) {
            console.log(e)
        }

        // Guardar en estado para enviar despues en un formulario

        /*const res = await fetch(
            "https://run.mocky.io/v3/c5189845-2a93-49aa-85c7-70bc64e8af90",
            {
                method: "POST",
                body: formData
            }
        );
        const data = await res.text();*/
        //console.log(formData);
    };

/*    const handleEnviar = async () => {
        console.log('handleEnviar', decodeImage)
        const respuesta = await pruebaEnviarDatos(decodeImage);
        console.log('respuesta', respuesta)
        //setRecibido(respuesta);
    };*/

    return (
        <div className="container">

            <div className="row">
                <div className="col card border-0 shadow my-4 p-2 text-center">
                    <h4>Registro de estudiante</h4>
                </div>
            </div>

            <div className="row">
                <div className="col card border-0 shadow my-4 p-2 text-center">
                    <form onSubmit={onFormSubmit}>

                        <div className="card col-md-8 mx-auto">
                            <div className="row">
                                <div className="mx-auto">

                                    <div className="input-group mb-3 mt-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">Img</span>
                                        </div>
                                        <div className="custom-file">
                                            <input className="custom-file-input" onChange={onChange} type="file" id="inputGroupFile01" accept="image/*" />
                                            <label className="custom-file-label text-left" htmlFor="inputGroupFile01">Tomar foto del estudiante</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            { newImage &&
                                <img className="card-img-top" src={files} alt="foto de perfil" />
                            }
                            <div className="card-body">
                                {   admitido.nombre &&
                                    <h5 className="card-title">Nombre: {admitido.nombre}</h5>
                                }

                                { admitido.documento &&
                                    <h5 className="card-text"><span>Documento:</span> {admitido.documento}</h5>
                                }
                                { pagos &&

                                    <h5 className="card-text">
                                        <span>Pagos: </span>
                                        ${pagos[0].valor}
                                    </h5>
                                }


                                <button className="btn btn-outline-dark" type="submit">Crear estudiante</button>
                            </div>
                        </div>

                        {/*<button className="btn btn-sm btn-danger mx-2" onClick={() => handleEnviar()}>Enviar</button>*/}

                    </form>

                </div>
            </div>


        </div>
    );

};

export default InscripcionCrearPage;

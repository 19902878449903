import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

const schema = yup.object().shape({
    email: yup
        .string("El correo debe ser un texto")
        .required("Debe ingresar un correo")
        .email("Debe ingresar un correo válido"),

    pass: yup
        .string("La contraseña debe ser alfanumérica")
        .min(8, "La contraseña debe tener al menos 8 caráteres")
        .required("Debes ingresar una contraseña")
})

export default yupResolver(schema)
import {Container, Row, Col, Card, Button} from 'react-bootstrap';
import useAuth from "../../auth/useAuth";
import DeleteModal from "./components/DeleteModal";
import ChangePasswordModal from "./components/ChangePasswordModal";
import useModal from "../../hooks/useModal";
import EditModal from "./components/EditModal";
import ProfilePicModal from "./components/ProfilePicModal";
import {ROLES} from "../../helpers/roles";

const AccountPage = () => {
    const {user/*, hasRole*/} = useAuth();

    const [isOpenDeleteModal, /*openDeleteModal,*/ closeDeleteModal] = useModal();
    const [isOpenChangePasswordModal, openChangePasswordModal, closeChangePasswordModal] = useModal();
    const [isOpenEditModal, openEditModal, closeEditModal] = useModal();
    const [isOpenProfilePicModal, openProfilePicModal, closeProfilePicModal] = useModal();


    return (
        <>
            <Container>
                <Row className="mt-4">
                    <Col xs={12} className="text-center">
                        <img
                            src={user?.profilePic || "/img/male_avatar.svg"}
                            alt="Profile"
                            onClick={openProfilePicModal}
                            style={{
                                width: '200px',
                                height: '200px',
                                borderRadius: '50%',
                                objectFit: 'cover',
                                cursor: 'pointer'
                            }}
                        />
                    </Col>
                    <Col className="mt-4">
                        <Card className="p-4 mx-auto" style={{maxWidth: '360px'}}>
                            <p className="text-center"><b>Nombre: </b> {user.name}</p>
                            <p className="text-center"><b>Email: </b> {user.email}</p>
                            <p className="text-center"><b>Rol:</b> {ROLES[user.role]}</p>

                            <Button variant="warning" onClick={openEditModal}>Cambiar nombre de usuario</Button>
                            <Button className="mt-1" variant="link" onClick={openChangePasswordModal}>Cambiar contraseña</Button>
                            {/*{ ( hasRole(roles.root) || hasRole(roles.administrador) ) ? '': <Button className="mt-3 text-danger" variant="link" onClick={openDeleteModal}>Eliminar cuenta</Button> }*/}
                        </Card>
                    </Col>
                </Row>
            </Container>
            <DeleteModal
                isOpen={isOpenDeleteModal}
                close={closeDeleteModal}
            />
            <ChangePasswordModal
                isOpen={isOpenChangePasswordModal}
                close={closeChangePasswordModal}
            />
            <EditModal
                user={user}
                isOpen={isOpenEditModal}
                close={closeEditModal}
            />
            <ProfilePicModal
                user={user}
                isOpen={isOpenProfilePicModal}
                close={closeProfilePicModal}
            />
        </>
    );
};

export default AccountPage;
import { Route, Switch} from 'react-router-dom';
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";

import AccountPage from "../pages/AccountPage/AccountPage";

import SumaryPage from "../pages/SumaryPage";

import NotFoundPage from "../pages/NotFoundPage";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import routes from "../helpers/routes";
import RootPage from "../pages/root/RootPage";
import roles from "../helpers/roles";
import AdmitidosCargarExcelPage from "../pages/admin/AdmitidosCargarExcelPage";
import RecaudoListadoPage from "../pages/admin/RecaudoListadoPage";
import RecaudoBuscarCrearPage from "../pages/admin/RecaudoBuscarCrearPage";
import RecaudoBuscarActualizarPage from "../pages/admin/RecaudoBuscarActualizarPage";
import RecaudoCrearPage from "../pages/admin/RecaudoCrearPage";
import RecaudoMasivoXLSXPage from "../pages/admin/RecaudoMasivoXLSXPage";
import CronogramaXlsxPage from "../pages/admin/CronogramaXLSXPage";

import useAuth from "../auth/useAuth";
import InscribirValidarPage from "../pages/Control/InscribirValidarPage";
import InscripcionCrearPage from "../pages/Control/InscripcionCrearPage";
import ConsultarInscripcionEstudianteAlimentoPage from "../pages/Control/ConsultarInscripcionEstudianteAlimentoPage";
import EntregaAlimento from "../pages/Control/EntregaAlimento";
import ListadoInscritos from "../pages/admin/ListadoInscritos";
import ListadoRecaudos from "../pages/admin/ListadoRecaudos";
import ResumenPage from "../pages/admin/ResumenPage";


const AppRouter = () => {
    const {hasRole}  = useAuth();
    return (
        /*<Router>*/
        /* El Router es usado en el App.js para poder acceder a
           las funciones del react-router-dom desde el AuthProvider
           para controlar la redirección desde allí */

            <Switch>
                <PublicRoute exact path={routes.home} component={HomePage}/>
                <PublicRoute exact path={routes.login} component={LoginPage}/>
                {/*<PublicRoute exact path={routes.register} component={RegisterPage}/>*/}
                <PrivateRoute exact path={routes.account} component={AccountPage}/>
                <PrivateRoute exact path={routes.sumary} component={SumaryPage}/>

                <PrivateRoute exact hasRole={ (hasRole(roles.administrador) ? roles.administrador: null) || (hasRole(roles.root) ? roles.root: null) } path={routes.admin.resumenAdmin} component={ResumenPage}/>

                {/*Administrador*/}
                <PrivateRoute hasRole={roles.administrador} exact path={routes.admin.cronograma} component={CronogramaXlsxPage}/>

                <PrivateRoute hasRole={roles.administrador} exact path={routes.admin.admitidos} component={AdmitidosCargarExcelPage}/>

                <PrivateRoute hasRole={roles.administrador} exact path={routes.admin.recaudoMasivoXLSX} component={RecaudoMasivoXLSXPage}/>

                <PrivateRoute hasRole={roles.administrador} exact path={routes.admin.recaudoBuscarCrear} component={RecaudoBuscarCrearPage}/>
                <PrivateRoute hasRole={roles.administrador} exact path={routes.admin.recaudoCrear} component={RecaudoCrearPage}/>
                <PrivateRoute hasRole={roles.administrador} exact path={routes.admin.recaudoBuscarActualizar} component={RecaudoBuscarActualizarPage}/>
                <PrivateRoute hasRole={roles.administrador} exact path={routes.admin.recaudoListado} component={RecaudoListadoPage}/>

                <PrivateRoute hasRole={roles.administrador} exact path={routes.admin.listadoInstritos} component={ListadoInscritos}/>
                <PrivateRoute hasRole={roles.administrador} exact path={routes.admin.listadoRecaudos} component={ListadoRecaudos}/>



                {/*acceso compartido administrador y control*/}
                <PrivateRoute hasRole={ (hasRole(roles.control) ? roles.control: null) || (hasRole(roles.administrador) ? roles.administrador: null) } exact path={routes.control.inscripcionValidar} component={InscribirValidarPage}/>
                <PrivateRoute hasRole={ (hasRole(roles.control) ? roles.control: null) || (hasRole(roles.administrador) ? roles.administrador: null) } exact path={routes.control.inscripcionCrear} component={InscripcionCrearPage}/>

                <PrivateRoute hasRole={ (hasRole(roles.control) ? roles.control: null) || (hasRole(roles.administrador) ? roles.administrador: null) } exact path={routes.control.alimentoConsultar} component={ConsultarInscripcionEstudianteAlimentoPage}/>
                <PrivateRoute hasRole={ (hasRole(roles.control) ? roles.control: null) || (hasRole(roles.administrador) ? roles.administrador: null) } exact path={routes.control.alimentoEntrega} component={EntregaAlimento}/>


                {/*<PrivateRoute exact path={routes.persona.home} component={PersonPage}/>
                <PrivateRoute exact path={routes.persona.crear} component={CreatePerson}/>
                <PrivateRoute exact path={routes.persona.actualizar} component={UpdatePerson}/>
                <PrivateRoute exact path={routes.persona.buscar} component={PersonFind}/>

                Muestras A
                Covid
                <PrivateRoute exact path={routes.muestra.covid.home} component={CovidHomePage}/>
                <PrivateRoute exact path={routes.muestra.covid.crear} component={CovidCreatePage}/>
                <PrivateRoute exact path={routes.muestra.covid.buscar} component={CovidFindPage}/>
                <PrivateRoute exact path={routes.muestra.covid.listar} component={CovidListPage}/>
                <PrivateRoute exact path={routes.muestra.covid.actualizar} component={CovidUpdatePage}/>




                Antigenos
                <PrivateRoute exact path={routes.muestra.antigeno.home} component={AntigenoHomePage}/>
                <PrivateRoute exact path={routes.muestra.antigeno.crear} component={AntigenoCreatePage}/>
                <PrivateRoute exact path={routes.muestra.antigeno.buscar} component={AntigenoFindPage}/>
                <PrivateRoute exact path={routes.muestra.antigeno.listar} component={AntigenoListPage}/>
                <PrivateRoute exact path={routes.muestra.antigeno.actualizar} component={AntigenoUpdatePage}/>
                Generar planilla antigenos
                <PrivateRoute exact path={routes.generar.antigenos} component={PlanillaAntigenosPage}/>
                <PrivateRoute exact path={routes.generar.antigenosResultados} component={PlanillaAntigenosResultadosPage}/>
                Listar resultados antigenos solo Laboratoristas, Administrador y Root
                <PrivateRoute hasRole={(hasRole(roles.laboratorista) ? roles.laboratorista: null) || (hasRole(roles.administrador) ? roles.administrador: null) || (hasRole(roles.root) ? roles.root: null)} exact path={routes.muestra.antigeno.buscarAntigenos} component={ResultadosAntigenoFindPage}/>
                <PrivateRoute hasRole={(hasRole(roles.laboratorista) ? roles.laboratorista: null) || (hasRole(roles.administrador) ? roles.administrador: null) || (hasRole(roles.root) ? roles.root: null)} exact path={routes.muestra.antigeno.resultadosLista} component={ResultadosAntigenoListPage}/>
                Ingresar resultados antigenos solo Laboratorista admin y root
                <PrivateRoute hasRole={(hasRole(roles.laboratorista) ? roles.laboratorista: null) || (hasRole(roles.administrador) ? roles.administrador: null) || (hasRole(roles.root) ? roles.root: null)} exact path={routes.muestra.antigeno.subirResultadosAntigenos} component={AntigenoUploadResultadosXLSXPage}/>
                Generar planilla sivigila
                <PrivateRoute exact path={routes.muestra.antigeno.antigenoSivigila} component={PlanillaSivigilaAntigenosPage}/>
                Ingresar resultados antigenos solo Root
                <PrivateRoute hasRole={hasRole(roles.root) ? roles.root: null} exact path={routes.muestra.antigeno.antigenoWord} component={PlanillaWordAntigenosPage}/>



                <PrivateRoute exact path={routes.muestra.buscar} component={SampleFindUpdatePage}/>
                <PrivateRoute exact path={routes.muestra.listar} component={SampleListPage}/>
                <PrivateRoute exact path={routes.muestra.crear} component={SamplePage}/>
                <PrivateRoute exact path={routes.muestra.actualizar} component={SampleUpdatePage}/>

                <PrivateRoute exact path={routes.project()} component={SumaryPage}/>
                <PrivateRoute hasRole={roles.administrador} exact path={routes.admin.users} component={AdminPage}/>
                <PrivateRoute hasRole={roles.root} exact path={routes.root.users} component={RootPage}/>*/}

                <PrivateRoute hasRole={roles.root} exact path={routes.root.users} component={RootPage}/>

                <Route path="*" component={NotFoundPage}/>
            </Switch>

        /*</Router>*/
    );
};

export default AppRouter;
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
//import roles from "../helpers/roles";

const schema = yup.object().shape({
    name: yup
        .string("El nombre debe ser un texto")
        .required("Debe ingresar un nombre"),
   /* email: yup
        .string("El correo debe ser un texto")
        .required("Debe ingresar un correo")
        .email("Debe ingresar un correo válido"),
    role: yup
        .string("El rol debe ser un texto")
        //.required("Debe seleccionar un rol")
        .oneOf(Object.keys(roles), "El rol no es válido, elija otro")
        //.oneOf(["regular", "admin"], "El rol no es válido, elija otro")*/
})

export default yupResolver(schema)
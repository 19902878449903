import {Alert, Button, Form} from "react-bootstrap";
import useAuth from "../../auth/useAuth";
import {useForm} from "react-hook-form";
import createUserResolver from "../../validations/createUserResolver";
import React, {useEffect, useState} from "react";
import {ROLES} from "../../helpers/roles";
import {toast} from "react-toastify";


const RootPage = () => {

    const {getAllUsers, resetUserPassword, deleteUser, createUser} = useAuth();
    const [usuarios, setUsuarios] = useState([]);


    const {register, handleSubmit, formState, reset} = useForm({resolver: createUserResolver});
    const {errors} = formState;

    useEffect(() => {
        async function obtenerUsuarios() {
            setUsuarios(await getAllUsers());
        }
        obtenerUsuarios();

    }, [getAllUsers]);



    const onSubmit = async (formData) => {

        const rol = formData.roles
        if(ROLES[1] === rol) {
            //Digitador
            formData.roles = ['11']
        }
        if(ROLES[2] === rol) {
            //Laboratorista
            formData.roles = ['10', '11']
        }
        if(ROLES[3] === rol) {
            //Admin
            formData.roles = ['01', '10', '11']
        }
        if(ROLES[4] === rol) {
            // Root
            formData.roles = ['00', '01', '10', '11']
        }

        //console.log(formData)
        const respuesta = await createUser(formData);
        if(respuesta) {
            toast.success('Usuario creado')
            setUsuarios([]);
            reset({
                username: '',
                email: '',
                role: ''
            });
        } else {
            toast.error('Error al crear usuario')
        }
    }




    const handleResetPassword = async (idUser) => {
        const respuesta = await resetUserPassword(idUser);
        if(respuesta) {
            toast.success('Contraseña reseteada')
        } else {
            toast.error('Error al resetear contraseña')
        }
    }
    const handleDeleteAccount = async (idUser) => {
        const respuesta = await deleteUser(idUser);
        if(respuesta) {
            toast.success('Usuario eliminado')
        } else {
            toast.error('Error al eliminar usuario')
        }
    }
    return (
        <div className="container mt-3">
            <div className="row">
                <div className="col-6 px-5">
                    <h3 className="text-center">Creación de usuarios</h3>

                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group>
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                placeholder="Escriba el nombre de usuario"
                                {...register("username")}
                                type="text"
                            />
                            {errors?.username && (
                                <Form.Text>
                                    <Alert variant="danger">
                                        {errors.username.message}
                                    </Alert>
                                </Form.Text>
                            )}
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Correo</Form.Label>
                            <Form.Control
                                placeholder="Escriba correo"
                                {...register("email")}
                                type="email"
                            />
                            {errors?.email && (
                                <Form.Text>
                                    <Alert variant="danger">
                                        {errors.email.message}
                                    </Alert>
                                </Form.Text>
                            )}
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Rol</Form.Label>
                            <Form.Control
                                as="select"
                                {...register("roles")}
                            >
                                <option value="">Seleccione una opción</option>
                                {ROLES.map( res => <option key={res}>{res}</option> )}
                            </Form.Control>
                            {errors?.roles && (
                                <Form.Text>
                                    <Alert variant="danger">
                                        {errors.roles.message}
                                    </Alert>
                                </Form.Text>
                            )}
                        </Form.Group>

                        <Button variant="outline-danger" onClick={handleSubmit(onSubmit)} block>
                            Crear usuario
                        </Button>
                    </Form>

                </div>
                <div className="col-6">
                    <h3 className="text-center mb-4">Listado de usuarios</h3>
                    <ul className="list-group">
                        {
                            usuarios.map((usuario, cont) => (
                                <li className="list-group-item col card border-0 shadow mb-2"key={usuario.id}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <div>
                                                        {cont + 1}) <span className="font-weight-bold">{usuario.username} </span>
                                                        - {ROLES[usuario.rol]}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="text-right">
                                                        {/*onClick={() => handleEditar(examen.identificador)}*/}
                                                        <button className="btn btn-sm btn-warning mx-2" onClick={() => handleResetPassword(usuario.id)}>Reset Password</button>
                                                        <button className="btn btn-sm btn-danger mx-2 mt-1" onClick={() => handleDeleteAccount(usuario.id)}>Eliminar usuario</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>

        </div>
    );
};

export default RootPage;
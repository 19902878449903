import {Container, Row, Col} from 'react-bootstrap';
import {Link} from "react-router-dom";
import routes from "../helpers/routes";

const NotFoundPage = () => {
    return (
        <Container>
            <Row className="mt-5">
                <Col md={{span: 6, offset: 3}} className="text-center">
                    <img
                        style={{
                            width: '100%'
                        }}
                        src="/img/404-not-found.svg"
                        alt="Error 404"/>

                    <h2 className="mt-1">¿Te has perdido?</h2>
                    <p>Vuelve al <Link to={routes.home}>inicio</Link></p>
                </Col>
            </Row>
        </Container>
    );
};

export default NotFoundPage;
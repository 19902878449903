import React from 'react';

const SumaryPage = () => {



    return (
        <div className="container my-5">

            <div className="row mb-4">
                <div className="col-md-12 mx-auto">
                    <div className="card shadow border-0 p-3">
                        <h3 className="text-center">Resumen</h3>



                    </div>
                </div>

            </div>

            <hr/>



        </div>
    );
};

export default SumaryPage;
import React from 'react';
import useAuth from "../../auth/useAuth";
import {useForm} from "react-hook-form";
import personFindResolver from "../../validations/personFindResolver";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import routes from "../../helpers/routes";
import {Alert, Button, Card, Col, Container, Form, Row} from "react-bootstrap";

const InscribirValidarPage = () => {
    const {buscarAdmitido, buscarRecaudo, buscarInscripcion} = useAuth();

    const {register, handleSubmit, formState, reset} = useForm({resolver: personFindResolver});
    const {errors} = formState;

    const history = useHistory();

    const onSubmit = async (formData) => {

        const admitido = await buscarAdmitido(formData.identificacion);
        if(!admitido) return toast.warn("El estudiante no ha sido Admitido!");

        const recaudo = await buscarRecaudo(formData.identificacion);
        if(!recaudo) return toast.warn("El estudiante no ha realizado Recaudo!");

        const inscripcion = await buscarInscripcion(formData.identificacion);
        if(!inscripcion) {
            reset();
            history.push({
                pathname: routes.control.inscripcionCrear,
                state: { admitido, recaudo }
            });
            return toast.warn("El estudiante no tiene Inscripción!");
        }
        reset();
        return toast.success("El estudiante ya tiene Inscripción!");






    }

    return (
        <Container>
            <Row className="mt-4">
                <Col xs={12} className="text-center">
                    <h2>Validar estudiante</h2>
                </Col>
                <Col className="mt-4">
                    <Card className="p-4 mx-auto border-0 shadow" style={{maxWidth: '360px'}}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group>
                                <Form.Label>Identificación del estudiante:</Form.Label>
                                <Form.Control
                                    placeholder="Escriba una identificación"
                                    {...register("identificacion")}
                                    type="text"
                                />
                                {errors?.identificacion && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.identificacion.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Button
                                className="mt-3"
                                variant="outline-dark"
                                block
                                onClick={handleSubmit(onSubmit)}
                            >Validar</Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default InscribirValidarPage;
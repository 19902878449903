import React from 'react';
import useAuth from "../../auth/useAuth";
import {useForm} from "react-hook-form";
import personFindResolver from "../../validations/personFindResolver";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import routes from "../../helpers/routes";
import {Alert, Button, Card, Col, Container, Form, Row} from "react-bootstrap";

const ConsultarInscripcionEstudianteAlimentoPage = () => {
    const {verificarInscripcionEstudiante, verificarConsumoEstudiante} = useAuth();

    const {register, handleSubmit, formState, reset} = useForm({resolver: personFindResolver});
    const {errors} = formState;

    const history = useHistory();

    const onSubmit = async (formData) => {

        const estudiante = await verificarInscripcionEstudiante(formData.identificacion);
        if(!estudiante) return toast.warn("El estudiante no tiene inscripción!");

        const consumo = await verificarConsumoEstudiante(formData.identificacion);
        if(!consumo) return toast.warn("El estudiante ya consumió!");

        reset();
        history.push({
            pathname: routes.control.alimentoEntrega,
            state: { estudiante }
        });


    }

    return (
        <Container>
            <Row className="mt-4">
                <Col xs={12} className="text-center">
                    <h2>Entrega de Alimento:</h2>
                </Col>
                <Col className="mt-4">
                    <Card className="p-4 mx-auto border-0 shadow" style={{maxWidth: '360px'}}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group>
                                <Form.Label>Identificación del estudiante:</Form.Label>
                                <Form.Control
                                    placeholder="Escriba una identificación"
                                    {...register("identificacion")}
                                    type="text"
                                />
                                {errors?.identificacion && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.identificacion.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Button
                                className="mt-3"
                                variant="outline-dark"
                                block
                                onClick={handleSubmit(onSubmit)}
                            >Buscar</Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ConsultarInscripcionEstudianteAlimentoPage;
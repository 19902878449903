import React from 'react';
import useAuth from "../../auth/useAuth";
import {useForm} from "react-hook-form";
import personFindResolver from "../../validations/personFindResolver";
import {useHistory} from "react-router-dom";
import routes from "../../helpers/routes";
import {toast} from "react-toastify";
import {Alert, Button, Card, Col, Container, Form, Row} from "react-bootstrap";

const RecaudoBuscarCrearPage = () => {
    const {buscarAdmitido, buscarRecaudo} = useAuth();

    const {register, handleSubmit, formState, reset} = useForm({resolver: personFindResolver});
    const {errors} = formState;

    const history = useHistory();

    const onSubmit = async (formData) => {

        const admitido = await buscarAdmitido(formData.identificacion);
        const recaudo = await buscarRecaudo(formData.identificacion);

        if(admitido){
            // Si el estudiante es admitido se procede a mostrar listado de recaudos
            if(recaudo){
                toast.warn("El estudiante Recaudó antes!")
            } else {
                toast.success("El estudiante no ha Recaudado!")
            }
            reset();
            history.push({
                pathname: routes.admin.recaudoCrear,
                state: { admitido }
            })
        } else {
            if(recaudo){
                toast.warn("El estudiante Recaudó antes!")
            }
            toast.warn("El estudiante no ha sido Admitido!")
        }


    }

    return (
        <Container>
            <Row className="mt-4">
                <Col xs={12} className="text-center">
                    <h2>Buscar crear recaudo</h2>
                </Col>
                <Col className="mt-4">
                    <Card className="p-4 mx-auto border-0 shadow" style={{maxWidth: '360px'}}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group>
                                <Form.Label>Identificación del estudiante:</Form.Label>
                                <Form.Control
                                    placeholder="Escriba una identificación"
                                    {...register("identificacion")}
                                    type="text"
                                />
                                {errors?.identificacion && (
                                    <Form.Text>
                                        <Alert variant="danger">
                                            {errors.identificacion.message}
                                        </Alert>
                                    </Form.Text>
                                )}
                            </Form.Group>
                            <Button
                                className="mt-3"
                                variant="outline-dark"
                                block
                                onClick={handleSubmit(onSubmit)}
                            >Buscar recaudo(s)</Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default RecaudoBuscarCrearPage;
import React from 'react';

import {useHistory} from "react-router-dom";
import useAuth from "../../auth/useAuth";
import {toast} from "react-toastify";
import routes from "../../helpers/routes";
//import roles from "../../helpers/roles";


const RecaudoListadoPage = ({location}) => {

    const { admitido, recaudo } = location.state;
    const {pagos} = recaudo;

    const history = useHistory();

    const{ eliminarPago } = useAuth();

    const totalRecaudo = () => {
        if(pagos){
            let total = 0;
            for(let i = 0; i < pagos.length; i++){
                total += pagos[i].valor;
            }
            return total;
        } else{

        } return 0;
    };

  /*  const handleEditar = async (idPago) => {

        /!*!// llamar a la api por los datos de la muestra, capturarlos y redireccionar a SivigilaUpdatePage
        const pago = await obtenerPago(idMuestra);
        if(muestra){
            history.push({
                pathname: routes.muestra.antigeno.actualizar,//'/muestra/actualizar',
                state: {muestra, persona}
            })
        } else {
            toast.error('Error al buscar la muestra')
        }*!/


        // Despues de editar ma muestra redireccionar a este listado y actualizar las muestras
    }*/

    const handleEliminar = async ( idPago, idRecaudo ) => {
        console.log(idPago)
        const pago = await eliminarPago(admitido.documento, idPago, idRecaudo);
        if(pago){
            toast.success('Pago eliminado correctamente')
            //const recaudo = await buscarRecaudo(admitido.documento);
            history.push({
                pathname: routes.sumary,
                //state: { admitido, recaudo }
            })
        } else {
            toast.error('Error al eliminar el pago')
        }

   /*     // llamar a la api por los datos de la muestra, capturarlos y redireccionar a SivigilaUpdatePage
        const muestra = await deleteSampleAntigeno(idPersona, idAntigenoArray, idAntigeno);
        if(muestra){
            toast.success('Prueba antígeno eliminada correctamente')

            history.push({
                pathname: routes.admin.recaudoListado
            })
        } else {
            toast.error('Error al eliminar la prueba antígeno')
        }*/

    }


    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="card border-0 shadow p-3 mt-4">
                        <h3 className="text-center">Listado de recaudos del estudiante</h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="card border-0 shadow p-3 my-3">
                        <p><span className="font-weight-bold">Estudiante:</span> {admitido.nombre} </p>
                        <p><span className="font-weight-bold">Identificación:</span> {admitido.documento}</p>
                        <p><span className="font-weight-bold">Recaudo:</span> ${totalRecaudo()}</p>


                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card border-0 shadow">

                        {
                            pagos &&
                                <table className="table table-hover table-sm small my-auto ">
                                    <thead>
                                    <tr>
                                        <th className="text-center" scope="col">#</th>
                                        <th className="text-center" scope="col">Fecha</th>
                                        <th className="text-center" scope="col">Valor</th>
                                        <th className="text-center" scope="col">Método</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {
                                        pagos.map(({ fecha, valor, metodo, _id }, indx) => (
                                            <tr key={_id}>
                                                <th className="text-center" scope="row">{indx + 1}</th>
                                                <td className="text-center">
                                                    {new Date(fecha).getDate()}/
                                                    {new Date(fecha).getMonth()+1}/
                                                    {new Date(fecha).getFullYear()} - {new Date(fecha).getHours()}:
                                                    {new Date(fecha).getMinutes()} Hrs
                                                </td>
                                                <td className="text-center">${valor}</td>

                                                <td className="text-center">{metodo}</td>
                                                <td className="text-center">
                                                    <div className="text-right">
                                                        {/*<button className="btn btn-sm btn-warning mx-2" onClick={() => handleEditar(_id)}>Editar</button>*/}
                                                        <button className="btn btn-sm btn-danger mx-2" onClick={() => handleEliminar(_id, recaudo._id)}>Eliminar</button>
                                                    </div>
                                                </td>

                                            </tr>
                                        ))

                                    }
                                    </tbody>
                                </table>

                        }


                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecaudoListadoPage;
import React from 'react';
import AppRouter from "./routers/AppRouter";
import AuthProvider from "./auth/AuthProvider";
import {BrowserRouter as Router} from "react-router-dom";
import Layout from "./component/layouts/Layout";
import {ToastContainer}  from "react-toastify";


const App = () => {
    return (
        <div>
            <Router>

                {/* El Router es usado en este nivel para poder acceder a
            las funciones del react-router-dom desde el AuthProvider */}
                <AuthProvider>
                    <Layout>
                        <AppRouter />
                    </Layout>
                </AuthProvider>
            </Router>
            {/*Para usar en todas las vistas (global) colocar a esta altura el ToastContainer
            será un contenedor para las alertas*/}
            <ToastContainer/>
        </div>
    );
};

export default App;
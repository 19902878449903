import {Navbar, Nav, NavDropdown} from 'react-bootstrap'
import {NavLink} from 'react-router-dom'
import routes from "../helpers/routes";
import useAuth from "../auth/useAuth";
import roles from "../helpers/roles";

const Navigation = () => {

    const {logout, isLogged, hasRole} = useAuth();

    return (
        /*<Navbar collapseOnSelect expand="lg" variant="dark" bg="dark">*/
        <Navbar collapseOnSelect expand="lg" variant="dark"   className="barra-navegacion">
            <div className="container">
                <Navbar.Brand as={NavLink} to={routes.home}>
                    <img className="mr-1 float-none" src="/img/logo_unipamplona.png" alt="logo ids" height="50px"/>
                    Comedor Universitario
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">

                        {
                            isLogged() &&
                            ( hasRole(roles.control) || hasRole(roles.entrega) ) &&
                            <Nav.Link as={NavLink} to={routes.sumary}>Resumen</Nav.Link>

                        }

                        {
                            isLogged() &&
                            ( hasRole(roles.administrador) || hasRole(roles.root) ) &&
                                <Nav.Link as={NavLink} to={routes.admin.resumenAdmin}>Resumen hoy</Nav.Link>

                        }

                        {
                            hasRole(roles.root) &&
                            <NavDropdown title="Root">
                                <NavDropdown.Item as={NavLink} to={routes.root.users}>Usuarios</NavDropdown.Item>
                            </NavDropdown>
                        }
                        {
                            hasRole(roles.administrador) &&
                            <NavDropdown title="Admin">
                                <NavDropdown.Header><span className="font-weight-bold text-danger">Subir XLS's:</span></NavDropdown.Header>
                                <NavDropdown.Item as={NavLink} to={routes.admin.cronograma}>Cronograma XLSX</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.admin.admitidos}>Admitidos XLSX</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.admin.recaudoMasivoXLSX}>Recaudo XLSX</NavDropdown.Item>

                                <NavDropdown.Divider/>

                                <NavDropdown.Header><span className="font-weight-bold text-danger">Recaudos individuales:</span></NavDropdown.Header>
                                <NavDropdown.Item as={NavLink} to={routes.admin.recaudoBuscarCrear}>Crear Recaudo</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.admin.recaudoBuscarActualizar}>Actualizar Recaudo</NavDropdown.Item>

                                <NavDropdown.Divider />

                                <NavDropdown.Header><span className="font-weight-bold text-danger">Descargar XLS's:</span></NavDropdown.Header>
                                <NavDropdown.Item as={NavLink} to={routes.admin.listadoInstritos}>Listado de inscritos</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.admin.listadoRecaudos}>Listado de recaudos</NavDropdown.Item>
                                {/*<NavDropdown.Item as={NavLink} to={routes.admin.estudiante}>Estudiante</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.admin.reporteFecha}>Reporte por fecha</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.admin.reporteTotal}>Reporte total</NavDropdown.Item>*/}

                            </NavDropdown>
                        }
                        {/*{
                            isLogged() &&
                            <NavDropdown title="Paciente">
                                <NavDropdown.Item as={NavLink} to={routes.persona.home}>Crear</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.persona.buscar}>Actualizar</NavDropdown.Item>
                            </NavDropdown>
                        }*/}

                        {/*{
                            isLogged() &&
                            <NavDropdown title="Muestra">
                                <NavDropdown.Item as={NavLink} to={routes.muestra.buscar}>Buscar</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.pdf}>Asociar PDF</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.eliminar}>Eliminar</NavDropdown.Item>
                            </NavDropdown>
                        }*/}

                        {/*{
                            isLogged() &&
                            <NavDropdown title="Muestras A">

                                <NavDropdown.Header><span className="font-weight-bold">COVID</span></NavDropdown.Header>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.covid.home}><span className="small font-weight-bold ml-3">Crear</span></NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.covid.buscar}><span className="small font-weight-bold ml-3">Buscar</span></NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.covid.pdf}><span className="small font-weight-bold ml-3">Asociar PDF</span></NavDropdown.Item>
                                <NavDropdown.Divider/>

                                <NavDropdown.Header><span className="font-weight-bold">CHICUNGUNYA</span></NavDropdown.Header>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.chicungunya.home}><span className="small font-weight-bold ml-3">Crear</span></NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.chicungunya.buscar}><span className="small font-weight-bold ml-3">Buscar</span></NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.chicungunya.pdf}><span className="small font-weight-bold ml-3">Asociar PDF</span></NavDropdown.Item>
                                <NavDropdown.Divider/>

                                <NavDropdown.Header><span className="font-weight-bold">CHAGAS</span></NavDropdown.Header>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.chagas.home}><span className="small font-weight-bold ml-3">Crear</span></NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.chagas.buscar}><span className="small font-weight-bold ml-3">Buscar</span></NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.chagas.pdf}><span className="small font-weight-bold ml-3">Asociar PDF</span></NavDropdown.Item>
                                <NavDropdown.Divider/>

                                <NavDropdown.Header><span className="font-weight-bold">LEISHMANIASIS</span></NavDropdown.Header>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.leishmaniasis.home}><span className="small font-weight-bold ml-3">Crear</span></NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.leishmaniasis.buscar}><span className="small font-weight-bold ml-3">Buscar</span></NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.leishmaniasis.pdf}><span className="small font-weight-bold ml-3">Asociar PDF</span></NavDropdown.Item>

                            </NavDropdown>
                        }*/}

                        {/*{
                            isLogged() &&
                            <NavDropdown title="Muestras B">


                                <NavDropdown.Header><span className="font-weight-bold">DENGUE</span></NavDropdown.Header>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.dengue.home}><span className="small font-weight-bold ml-3">Crear</span></NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.dengue.buscar}><span className="small font-weight-bold ml-3">Buscar</span></NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.dengue.pdf}><span className="small font-weight-bold ml-3">Asociar PDF</span></NavDropdown.Item>
                                <NavDropdown.Divider/>

                                <NavDropdown.Header><span className="font-weight-bold">TUBERCULOSIS</span></NavDropdown.Header>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.tuberculosis.home}><span className="small font-weight-bold ml-3">Crear</span></NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.tuberculosis.buscar}><span className="small font-weight-bold ml-3">Buscar</span></NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.tuberculosis.pdf}><span className="small font-weight-bold ml-3">Asociar PDF</span></NavDropdown.Item>
                                <NavDropdown.Divider/>

                                <NavDropdown.Header><span className="font-weight-bold">ZIKA</span></NavDropdown.Header>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.zika.home}><span className="small font-weight-bold ml-3">Crear</span></NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.zika.buscar}><span className="small font-weight-bold ml-3">Buscar</span></NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.zika.pdf}><span className="small font-weight-bold ml-3">Asociar PDF</span></NavDropdown.Item>


                            </NavDropdown>
                        }*/}

                        {
                            isLogged() &&
                            <NavDropdown title="Control">
                                {
                                    ( hasRole(roles.administrador) || hasRole(roles.control) ) &&
                                    <NavDropdown.Item as={NavLink} to={routes.control.inscripcionValidar}>Inscribir</NavDropdown.Item>
                                }

                                {
                                    ( hasRole(roles.administrador) || hasRole(roles.control) ) &&
                                    <NavDropdown.Item as={NavLink} to={routes.control.alimentoConsultar}>Entrega Alimento</NavDropdown.Item>
                                }

                               {/* <NavDropdown.Item as={NavLink} to={routes.muestra.antigeno.home}>Crear</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to={routes.muestra.antigeno.buscar}>Actualizar</NavDropdown.Item>

                                { ( hasRole(roles.laboratorista) || hasRole(roles.administrador) || hasRole(roles.root) ) &&
                                    <NavDropdown.Item as={NavLink} to={routes.muestra.antigeno.buscarAntigenos}>Listar resultados</NavDropdown.Item>
                                }

                                { ( hasRole(roles.laboratorista) || hasRole(roles.administrador) || hasRole(roles.root) ) &&
                                    <NavDropdown.Item as={NavLink} to={routes.muestra.antigeno.subirResultadosAntigenos}>Subir resultados</NavDropdown.Item>
                                }*/}

                            </NavDropdown>
                        }


                        {
                            /*isLogged() &&
                            <NavDropdown title="Entrega">
                                {/!*{
                                    hasRole(roles.tickets) &&
                                    <NavDropdown.Item as={NavLink} to={routes.generar.antigenos}>Planilla Antígenos</NavDropdown.Item>
                                }
                                {
                                    (hasRole(roles.almuerzos) || hasRole(roles.administrador) || hasRole(roles.root)) &&
                                    <NavDropdown.Item as={NavLink} to={routes.generar.antigenosResultados}>Planilla Antígenos</NavDropdown.Item>
                                }
                                {
                                    (hasRole(roles.laboratorista) || hasRole(roles.administrador) || hasRole(roles.root)) &&
                                    <NavDropdown.Item as={NavLink} to={routes.muestra.antigeno.antigenoSivigila}>Planilla Sivigila Antígenos</NavDropdown.Item>
                                }

                                {
                                    hasRole(roles.root) &&
                                    <NavDropdown.Item as={NavLink} to={routes.muestra.antigeno.antigenoWord}>Planilla Antígeno Word</NavDropdown.Item>
                                }*!/}
                            </NavDropdown>*/
                        }





                    </Nav>
                    <Nav>
                        {!isLogged() && <Nav.Link as={NavLink} to={routes.login}>Iniciar Sesión</Nav.Link>}
                        {/*!isLogged() && <Nav.Link as={NavLink} to={routes.register}>Registrarse</Nav.Link>*/}
                        {isLogged() && <Nav.Link as={NavLink} to={routes.account}>Mi Cuenta</Nav.Link>}
                        {isLogged() && <Nav.Link onClick={logout}>Cerrar Sesión</Nav.Link>}
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
};

export default Navigation;
import React from 'react';
import {Button, Image} from "react-bootstrap";
import {toast} from "react-toastify";
import routes from "../../helpers/routes";
import {useHistory} from "react-router-dom";
import useAuth from "../../auth/useAuth";

const EntregaAlimento = ({location}) => {
    const {estudiante} = location.state;

    const history = useHistory();

    const {registrarEntregaAlimentos} = useAuth();

    const handleRegistrarAlimento = async (documento) => {
        const entrega =  await registrarEntregaAlimentos(documento);
        console.log(entrega)
        if(!entrega) return toast.warn('El consumo no ha sido registrado, intente nuevamente')

        history.push({
            pathname: routes.control.alimentoConsultar,
        });
        return toast.success('El consumo registrado correctamente!')
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-6 mx-auto my-3 card border-0 shadow text-center">
                    <h3 className="my-1">Entrega de Alimentos</h3>

                    <div className="card my-2 pb-2 border-0">
                        <div className="card-body">
                            <h5 className="card-title">{estudiante.nombre}</h5>
                            <h5 className="card-title">Documento: {estudiante.documento}</h5>
                        </div>
                        <Button
                            className="mb-3"
                            variant="outline-danger"
                            block
                            onClick={() =>handleRegistrarAlimento(estudiante.documento)}
                        >Entregar Alimento</Button>
                        {/*<img className="card-img-top mt-2 px-2" src={estudiante.imagen} alt="Foto estudiante" width={320} height={240}/>*/}
                        <Image fluid rounded src={estudiante.imagen} alt="Foto estudiante"/>


                    </div>
                </div>
            </div>

        </div>
    );
};

export default EntregaAlimento;
import React, {useState} from 'react';
import {Alert, Button, Form} from "react-bootstrap";
import useAuth from "../../auth/useAuth";
import {useHistory} from "react-router-dom";
import {useForm} from "react-hook-form";
import recaudoResolver from "../../validations/recaudoResolver";
import routes from "../../helpers/routes";
import {toast} from "react-toastify";

const RecaudoCrearPage = ({location}) => {

    const [recaudos] = useState([]);

    console.log('RecaudoListadoPage', location.state)
    const { admitido } = location.state;

    const {buscarRecaudo, crearRecaudo} = useAuth();

    const history = useHistory();

    const {register, handleSubmit, formState, reset} = useForm({resolver: recaudoResolver});
    const {errors} = formState;

    const onSubmit = async (formData) => {
        formData.documento = admitido.documento;

        const recaudoCreado = await crearRecaudo(formData);

        if(recaudoCreado){
            // Si el estudiante es admitido se procede a mostrar listado de recaudos
            const recaudo = await buscarRecaudo(admitido.documento);
            reset();
            history.push({
                pathname: routes.admin.recaudoListado,
                state: { admitido, recaudo }
            })
        } else {
            if(recaudos){
                toast.warn("El estudiante Recaudó!")
            }
            toast.warn("El estudiante no ha sido Admitido!")
        }
    }

    return (
        <div className="container">
            <div className="row mt-4 ">
                <div className="col-md-8 mx-auto">
                    <div className="card border-0 shadow p-3 my-3">
                        <h4 className="text-center font-weight-bold mb-3">Crear Recaudo</h4>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-md-4">
                                    <Form.Group>
                                        <Form.Control
                                            placeholder="Ingrese un valor"
                                            {...register("valor")}
                                            type="number"
                                        />
                                        {errors?.valor && (
                                            <Form.Text>
                                                <Alert variant="danger">
                                                    {errors.valor.message}
                                                </Alert>
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group>
                                        <Form.Control
                                            as="select"
                                            {...register("metodo")}
                                        >
                                            <option value="">Método</option>
                                            {['Banco', 'Sucursal', 'Transferencia', 'Efectivo'].map( metodo => <option key={metodo}>{metodo}</option> )}
                                        </Form.Control>
                                        {errors?.metodo && (
                                            <Form.Text>
                                                <Alert variant="danger">
                                                    {errors.metodo.message}
                                                </Alert>
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </div>
                                <div className="col-md-4">
                                    <Form.Group>
                                        <Button
                                            variant="outline-dark"
                                            block
                                            onClick={handleSubmit(onSubmit)}
                                        >
                                            Crear
                                        </Button>
                                    </Form.Group>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default RecaudoCrearPage;
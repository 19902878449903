import {createContext, useEffect, useState} from 'react'
import {toast} from "react-toastify";
import {endpoint, headers} from "../helpers/configRequest";
import {useHistory} from "react-router-dom";


export const AuthContext = createContext();

const AuthProvider = ({children}) => {

    const [statusCode, setStatusCode] = useState(null);

    //////// API FETCH
    const request = async (method, path, body={}) => {
        const url = `${endpoint}${path}`;
        const options = { method, headers: headers(), mode: 'cors' }
        if(method === 'GET'){
        } else if(body){
            options.body = JSON.stringify(body)
        }

        try {
            const response = await fetch( url, options )

            if(response.status === 498){
                setStatusCode(498);
                localStorage.clear();
                setUser(null);
                toast.error('Su sesión ha expirado, por favor inicie nuevamente');
            }

            return response
        } catch (e) {
            console.log(e)
            return e
        }
    }

    const Api = {
        get(path) {
            return request('GET', path)
        },
        post(path, data = {}) {
            return request('POST', path, data)
        },
        put(path, data = {}) {
            return request('PUT', path, data)
        },
        delete(path) {
            return request('DELETE', path)
        }
    };
    ///////////////////
    const history = useHistory();

    const [user, setUser] = useState(
        JSON.parse(localStorage.getItem("user")) || null
    );

    useEffect(() => {
        localStorage.setItem('user', JSON.stringify(user));
    },[user]);

    const login = async (userCredential, fromLocation) => {

        try{
            const response = await Api.post('/auth/signin', userCredential);
            const result = await response.json();
            const {token, user} = result;

            if(response.status === 401){
                return false;
            } else if (statusCode === 498){
                setStatusCode(null);
                history.push('/sumary');
                return false;
            } else if(token && user) {
                localStorage.setItem('token', token);
                setUser({/*id: user.id, */name: user.name, email: user.email, role: user.role});
                history.push(fromLocation);
                return true;
            }

        } catch (e){
            console.log(e)
            return false
        }
    };

    const logout = async () => {
        setUser(null);
        localStorage.clear()
    };


    // Si el user existe me retorna true con la doble negación
    const isLogged = () => !!user;

    const hasRole = (role) => user?.role === role;

    const updateUser = async (newUserData, lugar) => {
        if(lugar === 'name'){
            const result = await Api.post('/users/update-account-data', {name: newUserData.name});

            //Si el resultado de la actualización es positivo, se actualizará la info en el frontend
            if(result.status === 202){
                setUser({
                    ...user,
                    ...newUserData
                })
                toast.success("Nombre acualizado correctamente")
            } else {
                toast.error("Error al actualizar nombre")
            }
        }

        if(lugar === 'password'){
            const result = await Api.post('/users/update-account-data', newUserData);
            //Si el resultado de la actualización es positivo, se actualizará la info en el frontend
            if(result.status === 202){

                /*setUser({
                    ...user,
                    ...newUserData
                })*/
                toast.success("Contraseña actualizada correctamente")
            } else if (result.status === 203){
                toast.error("Antigua contraseña incorrecta")
            }
        }
    }

    // funciones para crear pacientes
    const getPatient = async (data) => {
        const response = await Api.post('/patient/get-patient', {numDocumento: data});
        const result = await response.json();

        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const createPatient = async (data) => {
        const response = await Api.post('/patient/', data);
        const result = await response.json();

        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const updatePatient = async (data) => {
        const response = await Api.put('/patient/', data);
        const result = await response.json();
        //Si el resultado de la actualización es positivo, se actualizará la info en el frontend
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const updateSampleAntigeno = async (data) => {
        const response = await Api.put('/antigeno/update-sample-antigeno', data);
        const result = await response.json();
        //Si el resultado de la actualización es positivo, se actualizará la info en el frontend
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }


    // Funciones para crear muestras
    const createSampleCovid = async (data) => {
        const response = await Api.post('/sample/create-sample-covid', data);

        const result = await response.json();

        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const createSampleAntigeno = async (data) => {
        const response = await Api.post('/antigeno/create-sample-antigeno', data);

        const result = await response.json();

        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const getSampleCovid = async (idMuestra) => {
        const response = await Api.post('/covid/get-sample-covid', {idMuestra});

        const result = await response.json();

        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const getSampleAntigeno = async (idMuestra) => {
        const response = await Api.post('/antigeno/get-sample-antigeno', {idMuestra});

        const result = await response.json();

        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const getDataPlanillaAntigenos = async (data) => {
        const response = await Api.post('/antigeno/get-data-plantilla-antigeno', data);

        const result = await response.json();

        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const getDataPlanillaWordAntigenos = async (data) => {
        const response = await Api.post('/antigeno/get-data-plantilla-word-antigeno', data);

        const result = await response.json();

        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const getDataPlanillaAntigenosResultados = async (data) => {
        const response = await Api.post('/antigeno/get-data-plantilla-antigeno-resultados', data);

        const result = await response.json();

        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const getAllUsers = async () => {
        const response = await Api.get('/users');
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const resetUserPassword = async (idUser) => {
        const response = await Api.post('/users/reset-user-password', {idUser});
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const deleteUser = async (idUser) => {
        const response = await Api.post('/users/delete-user', {idUser});
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const createUser = async (userData) => {
        const response = await Api.post('/auth/signup', userData);
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const getResultadosAntigeno = async (fecha, brigada) => {
        const response = await Api.post('/antigeno/get-resultados-antigeno', {fecha, brigada});
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const getPlanillaSivigilaAntigenos = async (fecha, brigada) => {
        const response = await Api.post('/antigeno/get-sivigila', {fecha, brigada});
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const setAllAntigenoResultsXLSX = async (datos) => {
        const response = await Api.post('/antigeno/set-all-antigeno-resultados-xlsx', datos);
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }



    const deleteSampleAntigeno = async (idPersona, idAntigenoArray, idAntigeno) => {
        const response = await Api.post('/antigeno/delete-sample-antigeno', {idPersona, idAntigenoArray, idAntigeno});
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }



    //Funciones del ADMIN

    const almacenarListaAdmitidos = async (datos) => {
        const response = await Api.post('/admin/almacenar-listado-admitidos-xlsx', datos);
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const almacenarRecaudoMasivoXLSX = async (datos) => {
        const response = await Api.post('/admin/almacenar-recaudo-Masivo-xlsx', datos);
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const descargarListadoInscritos = async () => {
        const response = await Api.post('/admin/descargar-listado-inscritos');
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const descargarListadoRecaudos = async () => {
        const response = await Api.post('/admin/descargar-listado-recaudos');
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const almacenarCronogramaXLSX = async (datos) => {
        const response = await Api.post('/admin/almacenar-cronograma-xlsx', datos);
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const buscarAdmitido = async (datos) => {
        const response = await Api.post('/admin/buscar-admitido', {documento: datos});
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const buscarRecaudo = async (datos) => {
        const response = await Api.post('/admin/buscar-recaudo', {documento: datos});
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const verificarInscripcionEstudiante = async (datos) => {
        const response = await Api.post('/control/verificar-inscripcion-estudiante', {documento: datos});
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const verificarConsumoEstudiante = async (datos) => {
        const response = await Api.post('/control/verificar-consumo-estudiante', {documento: datos});
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const registrarEntregaAlimentos = async (datos) => {
        const response = await Api.post('/control/registrar-consumo-estudiante', {documento: datos});
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const contarConsumosHoy = async () => {
        const response = await Api.post('/control/contar-consumos-hoy');
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const buscarInscripcion = async (datos) => {
        const response = await Api.post('/control/buscar-inscripcion', {documento: datos});
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const contarInscritos = async () => {
        const response = await Api.post('/control/contar-inscritos');
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }
    /////////////////////////////////// PRUEBA ENVIAR IMAGEN Y DOCUMENTO
    const pruebaEnviarDatos = async (datos) => {
        const response = await Api.post('/control/uploadimage', datos);

        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }
    /////////////////////////////////////

    const crearRecaudo = async (datos) => {

        const response = await Api.post('/admin/crear-recaudo', datos);
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    const eliminarPago = async (documento, idPago, idRecaudo) => {

        const response = await Api.post('/admin/eliminar-pago', {documento, idPago, idRecaudo});
        const result = await response.json();
        if(response.status === 202){
            return result
        } else {
            return false;
        }
    }

    //////////////////////////////////////////////////////


    const contextValue = {
        user,
        isLogged,
        hasRole,
        login,
        logout,
        updateUser,

        //Root functions
        getAllUsers,
        resetUserPassword,
        deleteUser,
        createUser,


        //Funciones del paciente
        getPatient,
        createPatient,
        updatePatient,

        // Funciones de la muestra Covid
        createSampleCovid,
        getSampleCovid,

        // Funciones de la muestra antigeno
        createSampleAntigeno,
        getSampleAntigeno,
        updateSampleAntigeno,
        getDataPlanillaAntigenos,
        getResultadosAntigeno,
        setAllAntigenoResultsXLSX,
        getDataPlanillaAntigenosResultados,
        getPlanillaSivigilaAntigenos,
        deleteSampleAntigeno,
        getDataPlanillaWordAntigenos,

        //Funciones del admin
        almacenarListaAdmitidos,
        buscarAdmitido,
        buscarRecaudo,
        crearRecaudo,
        eliminarPago,
        almacenarRecaudoMasivoXLSX,
        almacenarCronogramaXLSX,
        descargarListadoInscritos,
        descargarListadoRecaudos,

        //Funciones control
        buscarInscripcion,
        verificarInscripcionEstudiante,
        registrarEntregaAlimentos,
        verificarConsumoEstudiante,
        contarConsumosHoy,
        contarInscritos,


        //////PRUEBA ENVIAR IMAGEN Y DOCUMENTO
        pruebaEnviarDatos
    }

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
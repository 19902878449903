import {Modal, Form, Button, Alert} from 'react-bootstrap';
import {useForm} from "react-hook-form";
import editAccountResolver from "../../../validations/editAccountResolver";
import {useEffect} from "react";
//import roles from "../../../helpers/roles";
import useAuth from "../../../auth/useAuth";


const EditModal = ({isOpen, close, user}) => {

    const {register, handleSubmit, formState, reset} = useForm({resolver: editAccountResolver});
    const {errors, dirtyFields} = formState;

    const {updateUser, /*hasRole*/} = useAuth();

    const isDirty = !!Object.keys(dirtyFields).length;

    const onSubmit = (formData) => {
        // No enviar peticiones al servidor si el usuario no ha editado ningun campo
        if(!isDirty) return;

        /*let newUserData;
        if(formData.role){
            newUserData = formData;
        } else {
            const {role, ...restFormData} = formData;
            newUserData = restFormData;
        }

        console.log(formData)
        console.log(newUserData) */
        // Actualizar campo
        updateUser(formData, 'name');
        close();
    }

    useEffect(() => {
        if(!isOpen){
            reset();
        }
    }, [isOpen, reset])

    useEffect(() => {
        if(user) reset({
            name: user.name,
        });
    }, [reset, user])

    return (
        <Modal show={isOpen} onHide={close}>
            <Modal.Header closeButton="x">
                <Modal.Title>Editar mi cuenta</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            placeholder="Escribe un nombre"
                            {...register("name")}
                            type="text"
                        />
                        {errors?.name && (
                            <Form.Text>
                                <Alert variant="danger">
                                    {errors.name.message}
                                </Alert>
                            </Form.Text>
                        )}
                    </Form.Group>
                    {/*<Form.Group>
                        <Form.Label>Correo</Form.Label>
                        <Form.Control
                            placeholder="Escribe un correo"
                            {...register("email")}
                            type="email"
                        />
                        {errors?.email && (
                            <Form.Text>
                                <Alert variant="danger">
                                    {errors.email.message}
                                </Alert>
                            </Form.Text>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Rol</Form.Label>
                        <Form.Control
                            as="select"
                            disabled={!hasRole(roles.admin)}
                            {...register("role")}
                        >
                            {Object.keys(roles).map( role => <option key={role}>{role}</option> )}
                        </Form.Control>
                        {errors?.role && (
                            <Form.Text>
                                <Alert variant="danger">
                                    {errors.role.message}
                                </Alert>
                            </Form.Text>
                        )}
                    </Form.Group>*/}
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={close}>Cancelar</Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isDirty}
                >Actualizar mi cuenta</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditModal;
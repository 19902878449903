import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {ROLES} from "../helpers/roles";

const schema = yup.object().shape({
    username: yup
        .string("El nombre de usuario debe ser String")
        .required("Debe ingresar un nombre de usuario")
        .min(4, "La contraseña debe tener al menos 4 caráteres"),
    email: yup
        .string("El correo debe ser string")
        .email("El correo debe ser un correo")
        .required("Debe ingresar el correo"),
    roles: yup
        .string("El rol es un String")
        .required("Seleccione un rol")
        .oneOf(ROLES.map( tipo => (`${tipo}`)), "El rol no es válido, elija uno"),
})

export default yupResolver(schema)
import React, {useEffect, useState} from 'react';

import {Button} from "react-bootstrap";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import useAuth from "../../auth/useAuth";


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const ResumenPage = () => {

    const {contarConsumosHoy, contarInscritos} = useAuth();

    const [consumos, setConsumos] = useState(0);
    const [inscritos, setinscritos] = useState(550);
    const [fecha, setFecha] = useState('DD/MM/AAAA');


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: `FECHA:  ${fecha}`,
            },
        },
    };

    const labels = ['Estudiantes', 'Entregados', 'Faltantes',];

    const data = {
        labels,
        datasets: [
            {
                label: 'Datos',
                data: [inscritos, consumos, (inscritos-consumos)],
                backgroundColor: 'rgb(168,51,51)',
            }
        ],
    };



    useEffect(() => {
        let anio = new Date().getFullYear('America/Bogota') + '';
        let mes = new Date().getMonth('America/Bogota') + 1 + '';
        let dia = new Date().getDate('America/Bogota') + '';
        dia = dia.length === 1 ? '0' + dia : dia;
        mes = mes.length === 1 ? '0' + mes : mes;

        setFecha(`${dia}/${mes}/${anio}`);
        const getInscritos = async () => {
            setinscritos(await contarInscritos())
            setConsumos(await contarConsumosHoy())
        }
        getInscritos();
    }, [contarInscritos,contarConsumosHoy] );

    const handleActualizarConsumos = async () => {
        setConsumos(await contarConsumosHoy())
        setinscritos(await contarInscritos())
    }

    return (
        <div className="container my-5">

            <div className="row mb-4">
                <div className="col-md-12 mx-auto">
                    <div className="card shadow border-0 p-3">
                        <h3 className="text-center">Resumen del día</h3>

                        {/*<p className="text-center">Consumos: {consumos}</p>*/}
                        <Bar options={options} data={data} />
                        <Button
                            className="mt-3"
                            variant="outline-danger"
                            block
                            onClick={() =>handleActualizarConsumos()}
                        >Actualizar</Button>

                    </div>
                </div>

            </div>

            <hr/>



        </div>
    );
};

export default ResumenPage;
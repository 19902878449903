import React, {useRef} from 'react';
import useAuth from "../../auth/useAuth";
import {toast} from "react-toastify";
import * as XLSX from "xlsx";
import {Card, Col, Container, Form, Row} from "react-bootstrap";

const CronogramaXlsxPage = () => {
    const {almacenarCronogramaXLSX} = useAuth();
    //const [dataFinal, setDataFinal] = useState([]);
    //const [cols, setCols] = useState([]);
    const inputFile = useRef();

    const SheetJSFT = [
        //"xlsx", "xlsb", "xlsm", "xls", "xml", "csv", "txt", "ods", "fods", "uos", "sylk", "dif", "dbf", "prn", "qpw", "123", "wb*", "wq*", "html", "htm"
        "xlsx"
    ].map(x => `.${x}`).join(",");

    const handleFile = (file) => {

        try{
            const {type, name} = file;
            const ext = name.split('.')[[name.split('.')].length]
            if(type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || ext !== "xlsx" ){
                inputFile.current.value = "";
                return toast.error('El documento no tiene extensión XLSX, debe ser un MS Excel')
            }
            const reader = new FileReader();
            reader.onload = async (e) => {
                /* Parse data */
                const ab = e.target.result;

                const wb = XLSX.read(ab, {type: 'array'});
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws, {header: 1});

                /* Update state */
                //ajustar data eliminando filas vacías antes de almacenarla en el estado
                const datosExcel = []
                for (let i = 0; i < data.length; i++) {
                    if (data[i].length > 0) {
                        if (i === 0) {
                            const fila = data[i];
                            const d0 = fila[0] !== undefined ? fila[0].toString().trim() : undefined;
                            const d1 = fila[1] !== undefined ? fila[1].toString().trim() : undefined;
                            const d2 = fila[2] !== undefined ? fila[2].toString().trim() : undefined;
                            datosExcel.push([d0, d1, d2]);
                        } else {
                            const fila = data[i];
                            const d0 = fila[0] !== undefined ? fila[0] : undefined;
                            const d1 = fila[1] !== undefined ? fila[1].toString().trim() : undefined;
                            const d2 = fila[2] !== undefined ? fila[2].toString().trim() : undefined;
                            datosExcel.push([d0, d1, d2]);
                        }
                    }
                }
                //console.log(datosExcel)

                //Verificar que el documento cuente con 5 encabezados
                const encabezados = datosExcel[0];
                if (encabezados.length !== 3) {
                    inputFile.current.value = "";
                    return toast.error('El documento de Admitidos solo debe tener 3 columnas, ver el ejemplo')
                }
                // verificar cada uno de los encabezados
                if (encabezados[0] !== 'N') {
                    inputFile.current.value = "";
                    return toast.error('La Columna A no tiene el encabezado N')
                }
                if (encabezados[1] !== 'DIA') {
                    inputFile.current.value = "";
                    return toast.error('La Columna B no tiene el encabezado DIA')
                }
                if (encabezados[2] !== 'FECHA') {
                    inputFile.current.value = "";
                    return toast.error('La Columna C no tiene el encabezado FECHA')
                }


                // verificar consecutivo columna N (no contemplar el encabezado)
                for (let i = 1; i < datosExcel.length; i++) {
                    const fila = datosExcel[i];

                    if(fila[0] === undefined) return toast.error(`El Documento de la Columna A, Fila ${i + 1} no es correcto`)

                    if (i !== fila[0]) {
                        inputFile.current.value = "";
                        //console.log('Consecutivo correcto: ', i, 'Incorrecto: ', fila[0])
                        return toast.error(`El consecutivo de la Columna A, Fila ${i + 1} no es correcto`)
                    } else if ((typeof fila[0]) !== "number") {
                        //console.log('(typeof fila[0]) !== "number"', typeof fila[0] !== "number")
                        inputFile.current.value = "";
                        return toast.error(`El consecutivo de la Columna A, Fila ${i + 1} debe ser de tipo numérico`)
                    }
                }

                // verificar columna DIA (no contemplar el encabezado)
                for (let i = 1; i < datosExcel.length; i++) {
                    const fila = datosExcel[i];

                    if(fila[1] === undefined) return toast.error(`El Día de la Columna C, Fila ${i + 1} no es correcto`)

                    if (fila[1].trim().length < 5) {
                        inputFile.current.value = "";
                        //console.log('Consecutivo correcto: ', i, 'Incorrecto: ', fila[0])
                        return toast.error(`El Día de la Columna C, Fila ${i + 1} no es correcto`)
                    }
                }

                // verificar columna "FECHA " (no contemplar el encabezado)
                for (let i = 1; i < datosExcel.length; i++) {
                    const fila = datosExcel[i];

                    if (fila[2].length === 10) {
                        // verificar que el formato sea DD/MM/AAAA
                        const pattern = /^((0[1-9]|[12][0-9]|3[01])(\/)(0[13578]|1[02]))|((0[1-9]|[12][0-9])(\/)(02))|((0[1-9]|[12][0-9]|3[0])(\/)(0[469]|11))(\/)\d{4}$/;

                        if (!pattern.test(fila[2])) {
                            console.log('pattern.test(fila[1])',!pattern.test(fila[2]), fila[2])
                            inputFile.current.value = "";
                            return toast.error(`Formato de fecha incorrecto en la Columna C, Fila ${i + 1}, debe ser DD/MM/AAAA`)
                        }

                    } else {
                        inputFile.current.value = "";
                        return toast.error(`Fecha incorrecta en la Columna C, Fila ${i + 1}, debe ser DD/MM/AAAA`)
                    }
                }

                // Guardar lista de admitidos en base de datos
                const respuesta = await almacenarCronogramaXLSX({excel: datosExcel});
                if(respuesta){
                    inputFile.current.value = "";
                    toast.success(respuesta.message)
                } else {
                    inputFile.current.value = "";
                    return toast.error('El cronograma no pudo ser guardado en DB')
                }

            };
            reader.readAsArrayBuffer(file);

        } catch (e) {
            console.log(e)
        }

    }

    const handleChange = (e) => {
        const files = e.target.files;
        if(files && files[0]) handleFile(files[0]);
    };

    return (
        <Container>
            <Row className="mt-4">
                <Col xs={12} className="text-center">
                    <h2>Cargar Cronograma XLSX</h2>
                </Col>
                <Col className="mt-4">
                    <Card className="p-4 mx-auto border-0 shadow" style={{maxWidth: '360px'}}>

                        <Form draggable="false">
                            <Form.Group>
                                <Form.Label htmlFor="file" className="small font-weight-bold">Elija el archivo excel (solo XLSX)</Form.Label>
                                <Form.Control
                                    type="file"
                                    id="file"
                                    accept={SheetJSFT}
                                    onChange={handleChange}
                                    ref={inputFile}
                                    draggable={false}
                                />
                            </Form.Group>
                        </Form>
                    </Card>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col className="mt-4">
                    <Card className="p-4 mx-auto border-0 shadow" style={{maxWidth: '360px'}}>
                        <img src="/img/EjemploCronograma.png" alt=""/>
                    </Card>
                </Col>
            </Row>

        </Container>



    );
};

export default CronogramaXlsxPage;
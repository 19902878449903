const routes = {
    home: '/',
    login: '/login',
    register: '/register',
    account: '/account',
    sumary: '/sumary',
    //project: (projectId) => (projectId ? `/projects/:${projectId}` : '/projects/:projectId'),
    admin: {

        cronograma: '/admin/almacenar-cronograma-XLSX',
        admitidos: '/admin/admitidos',

        recaudoMasivoXLSX: '/admin/recaudo-masivo-XLSX',

        recaudoBuscarCrear: '/admin/buscar-crear-recaudo',
        recaudoBuscarActualizar: '/admin/buscar-actualizar-recaudo',

        recaudoListado: '/admin/listado-recaudo',
        //recaudoActualizar: '/admin/actualizar-recaudo',
        recaudoCrear: '/admin/crear-recaudo',

        estudiante: '/admin/estudiante',
        reporteFecha: '/admin/reporte-fecha',
        reporteTotal: '/admin/reporte-total',
        inscribirBeneficiario: '/admin/inscribir-beneficiario',
        inscribirUsuario: '/admin/inscribir-usuario',

        listadoInstritos: '/admin/listado-inscritos',
        listadoRecaudos: '/admin/listado-recaudos',
        resumenAdmin: '/admin/resumen'
    },
    control: {
        inscripcionValidar: '/control/validar-inscripcion',
        inscripcionCrear: '/control/crear-inscripcion',

        alimentoConsultar: '/control/consultar-alimento',
        alimentoEntrega: '/control/entrega-alimento'

    },

    root: {
        users: '/root/users'
    },
    usuarios: {
        crear: '',
        editar: '',
        eliminar: ''
    },
    persona: {
        home:'/persona/home',
        crear: '/persona/crear',
        actualizar: '/persona/actualizar',
        buscar: '/persona/buscar'
    },
    /*muestra: {
        covid: {
            home: '/muestra/covid/home',
            crear: '/muestra/covid/crear',
            buscar: '/muestra/covid/buscar',
            listar: '/muestra/covid/listar',
            actualizar: '/muestra/covid/actualizar',
            pdf: 'muestra/covid/pdf'
        },
        chicungunya: {
            home: '/muestra/chicungunya/home',
            crear: '/muestra/chicungunya/crear',
            buscar: '/muestra/chicungunya/buscar',
            listar: '/muestra/chicungunya/listar',
            actualizar: '/muestra/chicungunya/actualizar',
            pdf: 'muestra/chicungunya/pdf'
        },
        chagas: {
            home: '/muestra/chagas/home',
            crear: '/muestra/chagas/crear',
            buscar: '/muestra/chagas/buscar',
            listar: '/muestra/chagas/listar',
            actualizar: '/muestra/chagas/actualizar',
            pdf: 'muestra/chagas/pdf'
        },
        leishmaniasis: {
            home: '/muestra/leishmaniasis/home',
            crear: '/muestra/leishmaniasis/crear',
            buscar: '/muestra/leishmaniasis/buscar',
            listar: '/muestra/leishmaniasis/listar',
            actualizar: '/muestra/leishmaniasis/actualizar',
            pdf: 'muestra/leishmaniasis/pdf'
        },
        dengue: {
            home: '/muestra/dengue/home',
            crear: '/muestra/dengue/crear',
            buscar: '/muestra/dengue/buscar',
            listar: '/muestra/dengue/listar',
            actualizar: '/muestra/dengue/actualizar',
            pdf: 'muestra/dengue/pdf'
        },
        tuberculosis: {
            home: '/muestra/turberculosis/home',
            crear: '/muestra/tuberculosis/crear',
            buscar: '/muestra/tuberculosis/buscar',
            listar: '/muestra/tuberculosis/listar',
            actualizar: '/muestra/tuberculosis/actualizar',
            pdf: 'muestra/tuberculosis/pdf'
        },
        zika: {
            home: '/muestra/zika/home',
            crear: '/muestra/zika/crear',
            buscar: '/muestra/zika/buscar',
            listar: '/muestra/zika/listar',
            actualizar: '/muestra/zika/actualizar',
            pdf: 'muestra/zika/pdf'
        },
        antigeno: {
            home: '/muestra/antigeno/home',
            crear: '/muestra/antigeno/crear',
            buscar: '/muestra/antigeno/buscar',
            listar: '/muestra/antigeno/listar',
            actualizar: '/muestra/antigeno/actualizar',
            resultadosLista: '/muestra/antigeno/resultados-lista',
            buscarAntigenos: '/muestra/antigeno/buscar-antigenos',
            subirResultadosAntigenos: '/muestra/antigeno/subir-resultados-antigeno',
            antigenoSivigila: '/muestra/antigeno/generar-planilla-sivigila',
            antigenoWord: '/muestra/antigeno/generar-planilla-antigeno-word',
        },

        buscar: '/muestra/buscar',
        crear: '/muestra/crear',
        listar: '/muestra/listar',
        actualizar: '/muestra/actualizar',
        pdf: '/muestra/asociar-pdf',
        eliminar: '/muestra/eliminar'
    },*/
    generar: {
        hoja: '/generar/hoja-de-trabajo',
        ficha:'/generar/ficha-epidemiologica',
        sivigila: '/generar/sivigila',
        datos: '/generar/datos-ins',
        antigenos: '/generar/planilla-antigenos',
        antigenosResultados: '/generar/planilla-antigenos-resultados'
    }

}

export default routes;
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
//import roles from "../helpers/roles";

const schema = yup.object().shape({
    valor: yup
        .number("Ingrese el valor")
        .typeError('Debe ingresar un valor')
        .required("Debe ingresar el valor a recaudar")
        .positive("Debe ser positivo")
        .integer("Debe ser un entero")
        .min(1, "Mínimo es 1")
        .max(500000, "Máximo es 50000"),
    metodo: yup
        .string("Ingrese el método de pago realizado")
        .required("Debe seleccionar el método usado en el recaudo")
        .oneOf(['Banco', 'Sucursal', 'Transferencia', 'Efectivo'], "El método no es válido, elija otro"),


   /* email: yup
        .string("El correo debe ser un texto")
        .required("Debe ingresar un correo")
        .email("Debe ingresar un correo válido"),
    role: yup
        .string("El rol debe ser un texto")
        //.required("Debe seleccionar un rol")
        .oneOf(Object.keys(roles), "El rol no es válido, elija otro")
        //.oneOf(["regular", "admin"], "El rol no es válido, elija otro")*/
})

export default yupResolver(schema)
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
//import roles from "../helpers/roles";

const schema = yup.object().shape({
    identificacion: yup
        .string("Ingrese el documento")
        .min(5, 'El documento debe tener mínimo 5 digitos')
        .required("Debe ingresar una identificación"),
   /* email: yup
        .string("El correo debe ser un texto")
        .required("Debe ingresar un correo")
        .email("Debe ingresar un correo válido"),
    role: yup
        .string("El rol debe ser un texto")
        //.required("Debe seleccionar un rol")
        .oneOf(Object.keys(roles), "El rol no es válido, elija otro")
        //.oneOf(["regular", "admin"], "El rol no es válido, elija otro")*/
})

export default yupResolver(schema)
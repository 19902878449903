import {Modal, Form, Button} from 'react-bootstrap';
import {useState} from "react";
import {toast} from "react-toastify";
import useAuth from "../../../auth/useAuth";


const ProfilePicModal = ({isOpen, close}) => {

    const {updateUser} = useAuth();

    const [fileName, setFilename] = useState('Subir una imagen');
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (e) => {
        const [file] = e.target.files;
        if(!file) return;

        const SIZE_8MB = 8 * 1024 * 1024;
        const isValidSize = file.size <= SIZE_8MB; // menor a 8 MB
        const isNameOfOneImageRegEx = /.(jpe?g|gif|png)$/i;
        const isValidType = isNameOfOneImageRegEx.test(file.name)
        if(!isValidSize) return toast.error("Imagen muy pesada, máximo 8MB");
        if(!isValidType) return toast.error("Solo puede subir imágenes");

        setFilename(file.name);

        const reader = new FileReader();

        reader.onloadend = () => {
            setSelectedFile(reader.result)
        }
        reader.readAsDataURL(file);
    }

    const handleUpdateProfilePic = () => {
        if(!selectedFile) return toast.error("Debe seleccionar una imagen");
        // Hacer la peticion al backend para hacer el cambio de imagen
        updateUser({
            profilePic: selectedFile
        });
        close();

    }

    return (
        <Modal show={isOpen} onHide={close}>
            <Modal.Header closeButton="x">
                <Modal.Title>Cambiar mi foto de perfil</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form>
                    <Form.File
                        custom
                        label={fileName}
                        data-browse="Subir"
                        onChange={handleFileChange}
                        accept=".jpg, .jpeg, .gif, .png"
                    />

                </Form>

                <img
                    className="img-fluid mt-2"
                    src={selectedFile}
                    alt="Profile pic preview"
                />
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={close}>Cancelar</Button>
                <Button variant="primary" onClick={handleUpdateProfilePic}>Actualizar imágen</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ProfilePicModal;
import {Modal, Form, Button, Alert} from 'react-bootstrap';
//import useAuth from "../../../auth/useAuth";
import {useForm} from "react-hook-form";
import changePasswordResolver from "../../../validations/changePasswordResolver";
import {useEffect} from "react";
import useAuth from "../../../auth/useAuth";


const ChangePasswordModal = ({isOpen, close}) => {

    const {updateUser} = useAuth();

    const {register, handleSubmit, formState, reset} = useForm({resolver: changePasswordResolver});
    const {errors} = formState;

    const onSubmit = (formData) => {
        //LLamar a la funcion de actualizacion del AuthProvider
        updateUser({oldPassword: formData.oldPassword, newPassword: formData.passwordB}, 'password')
        close();
    }

    useEffect(() => {
        if(!isOpen){
            reset();
        }
    }, [isOpen, reset])

    return (
        <Modal show={isOpen} onHide={close}>
            <Modal.Header closeButton="x">
                <Modal.Title>Cambiar contraseña</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Label>Contraseña anterior</Form.Label>
                        <Form.Control
                            placeholder="Escribe la contraseña anterior"
                            {...register("oldPassword")}
                            type="password"
                        />
                        {errors?.oldPassword && (
                            <Form.Text>
                                <Alert variant="danger">
                                    {errors.oldPassword.message}
                                </Alert>
                            </Form.Text>
                        )}
                    </Form.Group>
                    <br/>
                    <Form.Group>
                        <Form.Label>Nueva contraseña</Form.Label>
                        <Form.Control
                            placeholder="Escribe una nueva contraseña"
                            {...register("passwordA")}
                            type="password"
                        />
                        {errors?.passwordA && (
                            <Form.Text>
                                <Alert variant="danger">
                                    {errors.passwordA.message}
                                </Alert>
                            </Form.Text>
                        )}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Repite la nueva contraseña</Form.Label>
                        <Form.Control
                            placeholder="Repite la nueva contraseña"
                            {...register("passwordB")}
                            type="password"
                        />
                        {errors?.passwordB && (
                            <Form.Text>
                                <Alert variant="danger">
                                    {errors.passwordB.message}
                                </Alert>
                            </Form.Text>
                        )}
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={close}>Cancelar</Button>
                <Button variant="primary" onClick={handleSubmit(onSubmit)}>Actualizar contraseña</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChangePasswordModal;
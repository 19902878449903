import React, {useState} from 'react';

import useAuth from "../../auth/useAuth";
import {toast} from "react-toastify";
import { Button, Card, Col, Container, Row} from "react-bootstrap";

import ReactExport from 'react-data-export';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const ListadoInscritos = () => {
    const {descargarListadoInscritos} = useAuth();

    const [generarExcel, setGenerarExcel] = useState(null);
    const [multiDataSet, setMultiDataSet] = useState([{}]);


  /*  const onSubmit = async (formData) => {
        setBrigadaLugar(formData.brigada);
        const fecha = formData.fecha;

        let anio = fecha.getFullYear('America/Bogota') + '';
        let mes = fecha.getMonth('America/Bogota') + 1 + '';
        let dia = fecha.getDate('America/Bogota') + '';

        dia = dia.length === 1 ? '0' + dia : dia;
        mes = mes.length === 1 ? '0' + mes : mes;

        formData.fecha = `${dia}/${mes}/${anio}`

        const data = await getPlanillaSivigilaAntigenos(formData.fecha, formData.brigada);
        if (data) {
            setMultiDataSet(data);
            setFecha(formData.fecha.replaceAll('/', '-'));
            setGenerarExcel(true)
            toast.success('Planilla de antígenos generada exitosamente')
        } else {
            setGenerarExcel(false)
            toast.success('La planilla no pudo ser generada')
        }

    }*/
    const handleListadoInscritos = async () => {

        const listado = await descargarListadoInscritos();
        if(!listado) return toast.warn('El listado no pudo ser generado')

        setMultiDataSet(listado);
        setGenerarExcel(true)
        toast.success('Listado de inscritos generado exitosamente')
    }

    return (
        <Container>
            <Row className="mt-4">

                <Col className="mt-4">
                    <Card className="p-4 mx-auto text-center border-0 shadow" style={{maxWidth: '360px'}}>
                        <h2>Generar listado de inscritos</h2>
                            <Button
                                className="mt-3"
                                variant="outline-dark"
                                block
                                onClick={() => handleListadoInscritos()}
                            >Generar</Button>
                            {
                                generarExcel &&

                                <div>
                                    <ExcelFile element={<Button className="mt-3" variant="outline-dark" block>Descargar XLSX</Button>} filename={`ListadoInscritos`}>
                                        <ExcelSheet dataSet={multiDataSet} name="hoja 1"/>
                                    </ExcelFile>
                                </div>
                            }

                    </Card>
                </Col>

            </Row>
        </Container>
    );
};

export default ListadoInscritos;
import React, {useState} from 'react';

import useAuth from "../../auth/useAuth";
import {toast} from "react-toastify";
import { Button, Card, Col, Container, Row} from "react-bootstrap";

import ReactExport from 'react-data-export';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const ListadoRecaudos = () => {
    const {descargarListadoRecaudos} = useAuth();

    const [generarExcel, setGenerarExcel] = useState(null);
    const [multiDataSet, setMultiDataSet] = useState([{}]);

    const handleListadoInscritos = async () => {

        const listado = await descargarListadoRecaudos();
        if(!listado) return toast.warn('El listado no pudo ser generado')

        setMultiDataSet(listado);
        setGenerarExcel(true)
        toast.success('Listado de recaudos generado exitosamente')
    }

    return (
        <Container>
            <Row className="mt-4">

                <Col className="mt-4">
                    <Card className="p-4 mx-auto text-center border-0 shadow" style={{maxWidth: '360px'}}>
                        <h2>Generar listado de recaudos</h2>
                            <Button
                                className="mt-3"
                                variant="outline-dark"
                                block
                                onClick={() => handleListadoInscritos()}
                            >Generar</Button>
                            {
                                generarExcel &&

                                <div>
                                    <ExcelFile element={<Button className="mt-3" variant="outline-dark" block>Descargar XLSX</Button>} filename={`ListadoRecaudos`}>
                                        <ExcelSheet dataSet={multiDataSet} name="hoja 1"/>
                                    </ExcelFile>
                                </div>
                            }

                    </Card>
                </Col>

            </Row>
        </Container>
    );
};

export default ListadoRecaudos;